<template>
  <b-row>
    <b-col cols="12">
      <b-row>
        <b-col cols="4">
          <b-card no-header>
            <template slot="header">
              Organization Info
            </template>
            <b-form-group label="Name" label-for="name">
              <b-input-group>
                <b-form-input type="text" placeholder="Name" v-model.trim="organization.name"></b-form-input>
                <b-form-invalid-feedback>This is a required field</b-form-invalid-feedback>
              </b-input-group>
            </b-form-group>
            <b-form-group label="Notification Email" label-for="notificationEmailAddress">
              <b-input-group>
                <b-form-input type="text" placeholder="E-Mail Address" v-model.trim="organization.notificationEmailAddress.address"></b-form-input>
              </b-input-group>
            </b-form-group>
            <b-form-group label="Billing Email" label-for="billingEmailAddress">
              <b-input-group>
                <b-form-input type="text" placeholder="E-Mail Address" v-model.trim="organization.billingEmailAddress.address"></b-form-input>
              </b-input-group>
            </b-form-group>
            <template slot="footer">
              <b-row>
                <b-col sm="6">
                  <b-button size="sm" variant="danger" @click="del">Delete Org</b-button>
                </b-col>
                <b-col sm="6" class="text-right">
                  <b-button size="sm" variant="primary" @click="save">Save</b-button>
                </b-col>
              </b-row>
            </template>
          </b-card>
        </b-col>
        <b-col cols="4">
          <subscription :organizationId="organization.id"></subscription>
        </b-col>
        <b-col cols="4">
          <payment-profiles :organizationId="organization.id"></payment-profiles>
        </b-col>
      </b-row>
      <b-row>
        <b-col cols="12">
          <invoices :organizationId="organization.id"></invoices>
        </b-col>
      </b-row>
    </b-col>
  </b-row>
</template>

<script>
let roles = []
import { required, minLength, sameAs } from "vuelidate/lib/validators"
import Invoices from "../settings/subscription/components/Invoices"
import Subscription from "../settings/subscription/components/Subscription"
import PaymentProfiles from '../settings/subscription/components/PaymentProfiles'

export default {
  name: 'Organization',
  components: {
    Invoices,
    PaymentProfiles,
    Subscription
  },
  props: {
    caption: {
      type: String,
      default: 'Organization id'
    },
  },
  data: () => {
    return {
      organizations: [
        { value: null, text: 'Select Organization...', disabled: true },
        { value: null, text: 'No Parent Organization' }
      ],
      organization: {
        name: null,
        parentOrganizationId: null,
        billingEmailAddress: {
          address: null
        },
        notificationEmailAddress: {
          address: null
        }
      },
      subscriptions: []
    }
  },
  methods: {
    state (vField) {
      if (typeof(vField) != 'undefined') {
        return vField.$dirty ? !vField.$invalid : null
      }
    },
    touch (vField) {
      if (typeof(vField) != 'undefined') {
        vField.$touch()
      }
    },
    goBack() {
      this.$router.go(-1)
      // this.$router.replace({path: '/users'})
    },
    del () {
      this.$api.accounts.organizations.delete(this.$route.params.id)
        .then((response) => {
          this.$router.push({path: '/settings/organizations'})
        })
        .catch((err) => {
          this.$snotify.error('Error Deleting Organization')
        })
    },
    save () {
      this.$api.accounts.organizations.update(this.$route.params.id, this.organization)
        .then((response) => {
          this.$snotify.success('Organization Saved Successfully')
          this.organization = response.data
          this.$nextTick(() => { this.$v.$reset() })
        })
        .catch((err) => {
          this.$snotify.error('Error Saving Organization')
        })
    }
  },
  created () {
    this.$api.accounts.organizations.get(this.$route.params.id)
      .then((response) => {
        this.organization = response.data

        if (!this.organization.billingEmailAddress) {
          this.organization.billingEmailAddress = {
            id: null,
            address: null
          }
        }

        if (!this.organization.notificationEmailAddress) {
          this.organization.notificationEmailAddress = {
            id: null,
            address: null
          }
        }
      })
      .catch((err) => {
        this.$snotify.error('Error Fetching Organization')
      })

    this.$api.accounts.organizations.get()
      .then((response) => {
        response.data.forEach((organization) => {
          if (this.$route.params.id != organization.id) {
            this.organizations.push({ value: organization.id, text: organization.name })
          }
        })
      })
      .catch((err) => {
        console.log(err)
      })
  },
  validations: {
    organization: {
      name: {
        required
      }
    }
  }
}
</script>
